import { createSlice } from "@reduxjs/toolkit";

const storedToken = sessionStorage.getItem('token');

const initialState = {
    mode: "light",
    userId : null,
    token: storedToken || null,
    userName : null,
    userEmail : null,
    userLastName : null,
    userRole : null,
    img : null
};



export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === 'light' ? "dark" : 'light';
        },
        signIn: (state,action) => {
            state.token = action.payload;
        },
        setUserInfo: (state, action) => {
            const { userId, userName, userEmail , userLastName , userRole , img } = action.payload;
            state.userId = userId;
            state.userName = userName;
            state.userEmail = userEmail;
            state.userLastName = userLastName;
            state.userRole = userRole;
            state.img = img
        },
        logOut: () => initialState,
    }
});

export const { setMode , signIn , logOut , setUserInfo  } = globalSlice.actions;


export default globalSlice.reducer;
