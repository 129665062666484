import './App.css';
import { BrowserRouter as Router , Routes , Route, Navigate } from "react-router-dom";
import { CssBaseline , ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { themeSettings } from "./theme";
import Home from './pages/landing/landing';
import Contact from './pages/contact/contact';
import About from './pages/about/about';
import NotFound from './pages/notFound/notfound';
import { signIn } from "./state/index"; 
import DoctorsForm from './pages/doctorsForm/doctorsForm';
import Termes from './pages/termes-conditions/termes-conditions';
import Confidentialite from './pages/confidentialite-donnees/confidentialite-donnees';
import ScrollToTop from './components/ScrollToTop ';
import { AuthProvider, useIsAuthenticated } from "react-auth-kit";
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import Login from './pages/admin/login';
import Admin from './pages/admin/admin';
import Layout from './components/Layout';
import { setUserInfo , logOut } from "./state/index";


// PrivateRoute Component
const PrivateRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated() ? children : <Navigate to="/login" />;
};



function App() {
  const dispatch = useDispatch();
  const storedToken = sessionStorage.getItem("token");
  if (storedToken) {
      dispatch(signIn(storedToken));
  }

  const theme = useMemo(() => createTheme(themeSettings('light')), []);


  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    if (storedToken) {
      try {
        // Assuming the token has an 'exp' field indicating its expiration time
        const tokenData = JSON.parse(atob(storedToken.split(".")[1]));
        const isTokenValid = tokenData.exp * 1000 > Date.now(); // Convert seconds to milliseconds
      
        if (isTokenValid) {
          // Token is still valid, dispatch signIn and update userData
          dispatch(signIn(storedToken));
          const userDataString = sessionStorage.getItem("userData");
          if (userDataString) {
            const userData = JSON.parse(userDataString);
            dispatch(setUserInfo(userData));
          }
        } else {
          // Token is expired, clear stored data
          dispatch(logOut());
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("userData");

          document.cookie = '_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    
          // Clear the session
          sessionStorage.clear();
          window.location.reload();
        }
      } catch (error) {
        console.error("Error parsing or validating token:", error);
      }
    }
  }, [dispatch]);

  return (
    <div className="app">
      
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/doctor-signup" element={<DoctorsForm />} />
              <Route path="/termes-conditions" element={<Termes />} />
              <Route path="/confidentialite-donnees" element={<Confidentialite />} />
              <Route path="/login" element={<Login />} />

              <Route element={<AuthOutlet fallbackPath='/login' />}>
                <Route element={<Layout />}>
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/adminTwo" element={<Admin />} />
                </Route>
             </Route>
            


              <Route path="*" element={<NotFound />} />
            </Routes>
          </ThemeProvider>
        </Router>

    </div>
  );
}

export default App;