import React , {useState} from 'react'
import { LightModeOutlined, DarkModeOutlined, Menu as MenuIcon, Search, SettingsOutlined, ArrowDropDownOutlined  } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FlexBetween from './FlexBetween';
import { useDispatch, useSelector } from 'react-redux';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { AppBar, Button, IconButton, InputBase, Toolbar, useTheme ,Box , Typography , Menu , MenuItem } from '@mui/material';


function Navbar({
    user,
    isSidebarOpen,
    setIsSidebarOpen,
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const signOut = useSignOut()
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const clearSessionStorageForDomain = (domain) => {
        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i);
            if (key.startsWith(domain)) {
                sessionStorage.removeItem(key);
            }
        }
    };

    const handleLogout = () => {
        signOut();
        clearSessionStorageForDomain(".www.ferticonnect.com");
        // Clear all cookies for the domain
        const cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
            const cookieParts = cookies[i].split("=");
            const cookieName = cookieParts[0];
            document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.yourdomain.com";
        }
    
        // Clear the session
        sessionStorage.clear();
    
        // Reload the page
        window.location.reload();
    
        // Additional logout logic if needed
    
        // Redirect or perform any other action after logout
        // Example: window.location.href = '/login';
    };

    
    

    return (
        <AppBar
            sx={{
                position: "static",
                background: "none",
                boxShadow: "none",
            }}
        >
            <Toolbar sx={{
                justifyContent: "space-between"
            }} >
                {/* LEFT SIDE */}
                <FlexBetween>
                    <IconButton sx={{
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 10px"
                    }}  onClick={() => { setIsSidebarOpen(!isSidebarOpen) }} >
                        <MenuIcon />
                    </IconButton>
                   { /* <FlexBetween backgroundColor={theme.palette.background.alt} borderRadius="9px" gap="3rem" p=".1rem 1.5rem"  >
                        <InputBase placeholder='Search...' />
                        <IconButton>
                            <Search />
                        </IconButton>
                    </FlexBetween> */}
                </FlexBetween>

                {/* RIGHT SIDE */}
             
                <FlexBetween gap="1.5rem" >
                    {/*<IconButton onClick={() => dispatch(setMode())}>
                        {
                            theme.palette.mode === "dark" ? (
                                <LightModeOutlined sx={{ fontSize: "25px" }} />
                            ) : (
                                    <DarkModeOutlined sx={{ fontSize: "25px" }} />
                                )
                        }
                    </IconButton>
                    <IconButton>
                        <SettingsOutlined sx={{ fontSize  : "25px" }} />
                    </IconButton>*/}
                    <FlexBetween>
                        <Button onClick={handleClick} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" , textTransform : 'none', gap : "1rem"}} >
                            <Box  component="img" alt='profile' src={user.img} height="32px" width="32px" borderRadius='50%' sx={{ objectFit: "cover", border : "1px solid #83C4A8" }} />
                            <Box textAlign="left" >
                                      <Typography fontWeight="bold" fontSize="0.85rem" sx={{ color: "#83C4A8" }} >
                                          {user.name}
                                      </Typography>
                                      <Typography  fontSize="0.75rem" sx={{ color: 'black' }} >
                                          {user.email}
                                </Typography>
                                </Box>
                                <KeyboardArrowDownIcon  sx={{ color: '#00B185' , fontSize: '25px'}} />
                        </Button>
                        <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} >
                        <MenuItem onClick={handleLogout} >Log Out</MenuItem>
                        </Menu>
                    </FlexBetween>
                </FlexBetween>

            </Toolbar>
        </AppBar>
    ) 
}

export default Navbar
