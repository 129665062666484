import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQSection = ({ isMobile }) => {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <Box
      sx={{
        padding: isMobile ? "2em 1em" : "4em 10em",

        textAlign: "left",
      }}
    >
      <Typography
        variant={isMobile ? "h3" : "h1"}
        sx={{
          color: "#333333",
          fontWeight: "bold",
          marginBottom: "2em",
          textAlign: "center",
        }}
      >
        Foire aux questions :
      </Typography>

      {["panel1", "panel2", "panel3", "panel4", "panel5", "panel6"].map(
        (panel, index) => (
          <Accordion
            key={panel}
            expanded={expanded === panel}
            onChange={handleChange(panel)}
            sx={{
              backgroundColor: expanded === panel ? "#69A88D" : "#FFFFFF",
              color: "#FFFFFF", // No transition for color changes to be instantaneous
              borderRadius: "25px",
              marginBottom: "1em",
              boxShadow: expanded === panel ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
              "& .MuiAccordionSummary-root": {
                borderRadius: expanded ? "25px 25px 0 0" : "25px",
                backgroundColor: expanded === panel ? "#69A88D" : "#F8FAFC",
                boxShadow: '6px 7px 22px rgba(0, 0, 0, 0.08)',
                color: expanded === panel ? "#FFFFFF" : "#333333",
                transition: "background-color 0.5s ease", // Only apply transition to background color
              },
              "& .MuiAccordionDetails-root": {
                borderTop: expanded === panel ? "none" : "none",
                transition: "all 0.3s ease",
              },
              "&:before": {
                display: "none",
                }
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: expanded === panel ? "#FFFFFF" : "#333333" }}
                />
              }
            >
              <Typography variant="h3">
                {[
                  "Qu'est-ce que FertiConnect ?",
                  "Quels types de produits proposez-vous ?",
                  "Est-ce que les consultations sont confidentielles ?",
                  "Comment se passe la livraison des produits ?",
                  "Puis-je obtenir des recommandations personnalisées ?",
                  "Comment puis-je contacter le service client ?",
                ][index]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {[
                  "FertiConnect est une plateforme qui offre un accompagnement sur mesure pour la santé reproductive, visant à fournir un soutien personnalisé, une communauté inclusive, et des informations fiables.",
                  "Nous proposons une variété de produits liés à la santé reproductive, comprenant des ressources éducatives, des outils de suivi, et des suppléments adaptés.",
                  "Oui, toutes nos consultations sont entièrement confidentielles, en respectant la vie privée de chaque individu et en assurant la sécurité des données personnelles.",
                  "La livraison de nos produits est assurée par nos partenaires logistiques et se fait en toute sécurité, directement à votre domicile.",
                  "Absolument, notre équipe est disponible pour fournir des recommandations personnalisées, en tenant compte de vos besoins spécifiques.",
                  "Vous pouvez nous contacter via notre page de contact sur le site web, par email ou par téléphone. Nous sommes ici pour vous aider.",
                ][index]}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )
      )}
    </Box>
  );
};

export default FAQSection;
