import { InputAdornment, FormControl, Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import { LockOutlined, EmailOutlined } from '@mui/icons-material';

import { useTheme, useMediaQuery } from '@mui/material';
import InputField from "../../components/input";
import { Link } from 'react-router-dom';



import Email from '../../assets/fi-br-at.png';
import lock from '../../assets/fi-br-lock.png';
import BgSVG from '../../assets/female.png';
import IconSVG from '../../assets/logo.png';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setUserInfo, signIn } from '../../state/index';
import { useNavigate } from 'react-router-dom';
import useSignIn from 'react-auth-kit/hooks/useSignIn';


function Login() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [token, setToken] = useState('');
  const [waiting, setIsWaiting] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const signInFunction = useSignIn();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleSignIn = async () => {
      if (isSignedIn) {
        try {
          await signInFunction({
            auth: {
              token: token.token,
              type: 'Bearer',
            }
          });
          sessionStorage.setItem('token', token.token);
          sessionStorage.setItem('userData', JSON.stringify({
            userId : token.userId,
            userName: token.name,
            userEmail: token.userEmail,
            userRole: token.role,
            userLastName: token.lastName,
            img : token.img,
          }));
          dispatch(signIn(token.token));
          dispatch(
            setUserInfo({
              userId: token.userId,
              userName: token.name,
              userEmail: token.userEmail,
              userRole: token.role,
              userLastName: token.lastName,
              img : token.img,
            })
          );
          navigate('/admin');
        } catch (error) {
          console.error('Error during sign-in:', error);
        }
      }
    };

    handleSignIn();
  }, [isSignedIn, navigate, signInFunction, token, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = {
      email: document.querySelector('#email').value,
      password: document.querySelector('#password').value,
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.post(`${apiUrl}/user/admin`, userData, {
        /* headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Set the allowed origin for the request
        }, */
      });
      if (response.status === 201) {
        
        dispatch(signIn(response.data.token));
          dispatch(
            setUserInfo({
              userId: response.data.userId,
              userName: response.data.name,
              userEmail: response.data.userEmail,
              userRole: response.data.role,
              userLastName: token.lastName,
              img : token.img,
            })
          );
        //const responseData = await response.json();
        setToken(response.data);
        setIsSignedIn(true);
      } else {
        
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.response.data.error || 'Unknown error occurred');
    } finally {
      setIsWaiting(false);
    }
  };

  return (
    <Box className='Intro'  sx={{ display: 'flex', height: '100vh'  }}>
      {/* Left side without background image */}
      <Box 
        sx={{ 
          display: 'flex', 
          flex: 1, 
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent : "space-evently",
          padding : "10em 0em 2em 0em",
          userSelect :"none"
        }}>
        <img className='LogoIntro_Wezign'  src={IconSVG} alt="Icon" style={{  userSelect:"none" , WebkitUserSelect: "none" , userDrag :"none"}} />
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems : "center",
            userSelect : "none"
          }} >
          <Typography 
            component="h1" 
            variant="h3"
            className='Wlcm_wezign' 
            sx={{ 
              fontSize: 30,
              
              fontWeight: 'bold',
              marginBottom: '10px', 
              marginTop: '20px'
            }}
          >
            
          </Typography>
          <Typography 
            component="h1" 
            variant="h3" 
            sx={{ 
              fontSize: 30,
              
              fontWeight: 'bold',
              color:'#83C4A8',
              marginBottom: '10px', 
              marginTop: '10px' 
              }}
            >
            Admin Login
          </Typography>
          </Box>
          <form onSubmit={handleSubmit} variant="standard" style={{ width: '100%' }}>
            <Box sx={{ width: '100%', margin: '0 auto' }}>
              <Box sx={{
                display : "flex",
                flexDirection : "column",
                alignItems: "center",
                gap : "1em",
                margin : "2em 0em 2em 0em"
              }} >
              <InputField type="email" name="email" id="email" img={Email} color={"#83C4A8"} placeHolder="Email address"/>
              <InputField type="password" name="password" id="password" img={lock} color={"#83C4A8"} placeHolder="Password" />
              </Box>
          
              {error && (
                <Box sx={{
                  display:"flex",
                  justifyContent:"center"
                }} >
                  <Typography variant="body2" style={{ color: 'red', marginBottom: '16px' }}>
                  {error}
                </Typography>
                </Box>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#83C4A8',
                  borderRadius: '77px',
                  marginBottom: '20px',
                  width: "17em",
                  height: "3em",
                  fontSize: "larger",
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  border: '1px solid #83C4A8',

                  '&:hover': {
                    backgroundColor: '#83C4A8',
                  },
                }}
                disabled={waiting}
              >
                {waiting ? 'Logging in...' : 'Log in'}
              </Button>
              
              
            </Box>
          </form>

          
      </Box>
      {/* Right side with background image */}
      {/* <Box 
      className='rightSideLogin'
        sx={{
          flex: '1',
          width: '100%', // Set width to 100% on mobile, otherwise 50%
          height: '100%', // Always take up all the height of the screen
        }}
      >
        <img
          src={BgSVG}
          alt="Background"
          style={{
            width: '100%', // Ensure the image takes up the entire width of its container
            height: '100%', // Ensure the image takes up the entire height of its container
            objectFit: 'cover',
          }}
        />
      </Box> */}
    </Box>
  ) 

      
}

export default Login;

