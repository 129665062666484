import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Modal,
    Backdrop,
    Fade,
    Box,
    Tooltip,
    Chip,
} from "@mui/material";
import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined"; // File icon
import BadgeOutlined from "@mui/icons-material/BadgeOutlined"; // ID icon
import { useAcceptDoctorMutation, useDenyDoctorMutation } from "../state/api";

const statusColors = {
    inReview: "warning",
    accepted: "success",
    refused: "error",
};

const DoctorApplicationsTable = ({ applications }) => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [acceptDoctor] = useAcceptDoctorMutation();
    const [denyDoctor] = useDenyDoctorMutation();

    const handleOpen = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage("");
    };

    const handleAccept = async (id) => {
        await acceptDoctor(id);
    };

    const handleDeny = async (id) => {
        await denyDoctor(id);
    };

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Gender</TableCell>
                        <TableCell>Professional Address</TableCell>
                        <TableCell>Professional Status</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Documents</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {applications.map((form) => (
                        <TableRow key={form._id}>
                            <TableCell>{`${form.prenom} ${form.nom}`}</TableCell>
                            <TableCell>{form.email}</TableCell>
                            <TableCell>{form.telephone}</TableCell>
                            <TableCell>{form.gender}</TableCell>
                            <TableCell>{form.adresseProfessionnelle}</TableCell>
                            <TableCell>{form.situationProfessionnelle}</TableCell>
                            <TableCell>
                                <Chip
                                    label={form.doctorStatus}
                                    color={statusColors[form.doctorStatus]}
                                />
                            </TableCell>
                            <TableCell>
                                {form.files?.map((file, index) => (
                                    <Tooltip key={index} title={`View File ${index + 1}`}>
                                        <IconButton
                                            onClick={() =>
                                                handleOpen(
                                                    `https://www.ferticonnect.com/public/${file.split("/").pop()}`
                                                )
                                            }
                                        >
                                            <InsertDriveFileOutlined />
                                        </IconButton>
                                    </Tooltip>
                                ))}
                                <Tooltip title="View ID">
                                    <IconButton
                                        onClick={() =>
                                            handleOpen(
                                                `https://www.ferticonnect.com/public/${form.idFile.split("/").pop()}`
                                            )
                                        }
                                    >
                                        <BadgeOutlined />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                {form.doctorStatus === "inReview" && (
                                    <>
                                        <IconButton
                                            color="success"
                                            onClick={() => handleAccept(form._id)}
                                            sx={{ marginRight: 1 }}
                                        >
                                            ✔
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDeny(form._id)}
                                        >
                                            ✖
                                        </IconButton>
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* Modal for viewing images */}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            outline: "none",
                            maxWidth: "80%",
                            maxHeight: "80%",
                        }}
                    >
                        <img
                            src={selectedImage}
                            alt="Uploaded Document"
                            style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "100%",
                                objectFit: "contain",
                            }}
                        />
                        <Box textAlign="center" sx={{ mt: 2 }}>
                            <IconButton
                                onClick={handleClose}
                                color="primary"
                            >
                                Close
                            </IconButton>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default DoctorApplicationsTable;
