import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useGetDoctorFormsQuery } from "../../state/api";
import DoctorApplicationsTable from "../../adminComponents/DoctorApplicationsTable";


function Admin() {
    const { data: doctorForms, isLoading } = useGetDoctorFormsQuery();

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ p: 3, color: "black" }}>
                <Typography variant="h3">Admin Dashboard</Typography>
                <Typography>Welcome to the admin panel!</Typography>
            </Box>
            <Box>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Doctor Applications
                </Typography>
                {isLoading ? (
                    <CircularProgress />
                ) : doctorForms?.length > 0 ? (
                    <DoctorApplicationsTable applications={doctorForms} />
                ) : (
                    <Typography>No doctor applications found.</Typography>
                )}
            </Box>
        </Box>
    );
}

export default Admin;
